import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UserModel} from '../models/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {AuthenticationService, ProjectService} from '../../_services';
import {TranslateService} from '@ngx-translate/core';
import {DropdownOptions} from '../../_models/dropdown-options';
import {AppConfig} from '../../helpers/common/app.config';
import { CodeInputComponent } from 'angular-code-input';
import {environment} from '../../../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app-register',
  templateUrl: `../views/register.component.html`,
  styleUrls: ['../styles/auth-style.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    MessageService,
  ]
})
export class RegisterComponent implements OnInit {
  public user: UserModel;
  public days: Array<number>;
  public months: Array<number>;
  public years: Array<number>;
  public dropDownOptions = DropdownOptions;
  public mobileCountryCode = '+41';
  public mobilePhone;
  public logoPath = null;
  public tosFilePath = null;
  public agencyWeb = null;
  public agencyName = null;
  public isDropdownVisible = false;
  public passwordTypeField = true;
  public showPasswordDialog = false;

  public passwordToAddTooPool = '';
  public termsAndConditionsChecked = false;
  public agencyTermsAndConditionsChecked = false;
  hideform = false;
  displayDialog = false;
  displayRegisterDialog = false;
  displayTermsAndConditions = false;
  spoolTermsAndConditions = false;
  dialogContent = '';
  registerDialogContent = '';
  successfullyRegisterDialog = false;

  public showBrowserAlert = true;
  public isChrome = navigator.userAgent.indexOf("Chrome") != -1;
  public agreed = localStorage.getItem('chrome_suggestion_alert');
  
  public verifyingCode = false;
  public loadingCode = false;
  public incorrectCode = false;
  public code = '';
  public currentLanguage = localStorage.getItem('Language');

  public mainColor: string;

  public sendingCode = false;
  @ViewChild('codeInput') codeInput !: CodeInputComponent;

  public logoUrl = environment.logoUrl;
  
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    public translate: TranslateService,
    private projectService: ProjectService,
  ) {
    this.mainColor = environment.agencyName === "Hotelis" ? "#033768" : "#16a085";
    this.user = new UserModel();
    this.user.salutation = 'Mr';
    const agencyId = this.route.snapshot.paramMap.get('id');

    this.user.email = this.route.snapshot.queryParams.email;
    this.user.first_name = this.route.snapshot.queryParams.name;
    this.user.last_name = this.route.snapshot.queryParams.lastname;

    if (agencyId !== null) {
      this.user.agency_name = agencyId;
    }
  }

  ngOnInit() {
    this.days = [];
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }

    this.months = [];
    for (let i = 1; i <= 12; i++) {
      this.months.push(i);
    }

    this.years = [];
    const currentYear = (new Date()).getFullYear();
    for (let i = currentYear; i >= 1950; i--) {
      this.years.push(i);
    }
    if (localStorage.getItem('currentUser')) {
      // this.router.navigate(['/login']);
    }
    
    this.projectService.getAgencyLogoPath(this.user.agency_name).subscribe(response => {
        response.logo_path ? this.logoPath = AppConfig.getAgencyFileParh(response.agency_id) + response.logo_path : this.logoPath = null;
        this.tosFilePath = AppConfig.getAgencyFileParh(response.agency_id) + response.tos_file;
        this.agencyName = response.agency_name;
        if (response.website.includes('http')) {
          this.agencyWeb = response.website;
        } else {
          this.agencyWeb = 'https://' + response.website
        }
    }, 
    error => {
      console.log(error);
    }
    )
    this.currentLanguage = localStorage.getItem('Language');
  }

  agreeOnChromeAlert() {
    localStorage.setItem('chrome_suggestion_alert', JSON.stringify(new Date()));
    this.showBrowserAlert = false
  }

  mobileTrim() {
      let mobile = this.mobilePhone.trim();
      if (mobile.length) {
          const lastChar = mobile[mobile.length - 1];
          if (!this.isNumber(lastChar)) {
              mobile = mobile.substring(0, mobile.length - 1);
          }
      }
      this.mobilePhone = mobile;
  }

  isNumber(c) {
      return Number.isInteger(+c);
  }

  checkIfEmailExists() {
    this.authService.checkIfUserExists(this.user).subscribe(response => {
      if (response.exists) {
        if (response.same_agency) {
            this.translate.get('You are already registered for this agency, you will be redirected to login!').subscribe(res => {
                this.dialogContent = res;
                this.displayDialog = true;
            });
        } else {
              this.successfullyRegisterDialog = true;
          // this.redirectToLogin();
        }
      }
    });

  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  onSubmit(profileForm) {
    if (!profileForm.valid) {
      return;
    }
    this.user.email = this.user.email.toLowerCase();
    if ( this.mobilePhone[0] == 0) this.mobilePhone = this.mobilePhone.substr(1);
    this.user.mobile = this.mobileCountryCode + this.mobilePhone;
    this.user.is_social = false;
    this.user.language = localStorage.getItem('Language');

    

    if (!this.verifyingCode) {
      this.verifyAndSendCode(this.user);
    } else {
      this.register(this.user);
    }
    
    return;
  }

  showToast() {
      this.messageService.add({severity: 'success', summary: this.translate.instant('Success!')});
  }

  verifyAndSendCode(user) {
    this.sendingCode = true;
    this.authService.validateAndSendCode(user).subscribe(
      response => {
        this.sendingCode = false;
        if (response.status === 'error') {
          this.translate.get(response.message).subscribe(res => {
              this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          });
          this.user.password = '';
        } else {
          this.verifyingCode = true;
        }
      },
      error => {
        console.log('errori');
        this.sendingCode = false;
      },
    );
  }

  register(user) {
    this.loadingCode = true;
    this.authService.register(user).subscribe(
      response => {
        this.incorrectCode = false;
        if (response.status === 'error') {
            this.translate.get(response.message).subscribe(res => {
                this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
            });
          this.user.password = '';
        } else {
            if (response.add_to_pool) {
                if(this.agencyName){
                  this.showPasswordDialog = true; 
                  this.loadingCode = false;
                } else {
                  this.messageService.add({
                    severity: 'error',
                    summary: this.translate.instant('Error!'),
                    detail: this.translate.instant('Employee is already registered please login!') });
                }
                    
            } else {
                this.redirectToLogin();
            }
        }
      },
    error => {
      console.log('errori');
      this.loadingCode = false;
      this.incorrectCode = true;
    }
    );
  }

  passwordCheck() {
    this.authService.login({email: this.user.email, password: this.passwordToAddTooPool}).subscribe( response => {
      if (response.status === 'success') {
          this.showPasswordDialog  = false;
          this.checkIfEmailExists();
      }
    })
  }

  onCodeChanged(code: string) {
    if(code.length < 4) {
      this.incorrectCode = false;
    }
    this.code = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    console.log(code);
    this.user.code = code;
    this.register(this.user);
  }

  verify(){
    this.loadingCode = true;
    if(this.code.length == 4) {
      this.register(this.user);
    } else {
      this.translate.get('incorrect code').subscribe(res => {
        this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
      });
    }
    this.loadingCode = false;
  }

  resendCode() {
    this.loadingCode = true;
    this.authService.resendCode(this.user).subscribe(response => {
      if (response.status === 'error') {     
        this.translate.get('failed to send code').subscribe(res => {
          this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
        });
        this.loadingCode = false;
      } else {  
        this.translate.get('code sent sucessfully').subscribe(res => {
          this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
        });
        this.codeInput.reset();
        this.incorrectCode = false;
        this.loadingCode = false;
      }
    });
  }

  getTermsAndConditionsUrl(): string {
    switch (this.currentLanguage) {
      case 'de':
        return 'https://job.rocks/allgemeine-geschaftsbedingungen';
      case 'fr':
        return 'https://job.rocks/fr/conditions-generales';
      case 'it':
        return 'https://job.rocks/it/condizioni-generali';
        case 'en':
        return 'https://job.rocks/en/terms-and-conditions/';
      default:
        return 'https://job.rocks/allgemeine-geschaftsbedingungen';
    }
  }

}
