import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class EmployeeService {
  constructor(private http: HttpClient) {
  }

  getEmployees(page, orderColumn, orderAsc, name, filter, groupIds, blacklist = false) {
    let orderString = '';
    let filterString = '';
    let groupsString = '';
    let nameString = '';
    let blacklistString = '';

    if (orderColumn) {
      const direction = orderAsc ? 'asc' : 'desc';
      orderString = '&order_by=' + orderColumn + '&direction=' + direction;
    }

    if (groupIds.length > 0) {
      groupsString = '&group_ids=' + groupIds;
    }

    if (name.length > 0) {
      nameString = '&name=' + name;
    }

    if(blacklist){
      blacklistString = '&blacklist=' + blacklist;
    }

    let body = {};

    if (filter) {
      body = {filter: filter};
    }
    return this.http.post<any>(`${environment.apiUrl}/employee?page=${page + orderString + nameString + groupsString + blacklistString}`, body);
  }

  getDaysAndHoursOfWork(id, from = null, to = null) {
    return this.http.get<any>(`${environment.apiUrl}/employee/days-hours-of-work/${id}?from=${from}&to=${to}`)
  }

  getEmployeeRatings(employee_id) {
    return this.http.get<any>(`${environment.apiUrl}/employee/ratings/${employee_id}`)
  }
  
  getAgencyGroups(param = null) {
    param = param || {};
    param.branches = param.branches || [];

    let brs = '';
    param.branches.forEach(b => {
        brs = brs + (brs.length !== 0 ? "," : '') + b;
    });

    let branches = param.branches.length ? `&branches=${brs}` : '';
    return this.http.get<any>(`${environment.apiUrl}/employee/groups?${branches}`);
}
  

  getConsultants(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/consultants`, param);
  }

  setConsultant(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/consultant`, param);
  }

  getMultipleAgencyGroups(agencyIds) {
    return this.http.get<any>(`${environment.apiUrl}/employee/multi-agency-groups?agency_ids=${agencyIds}`);
  }

  getAgencyBlacklistGroups() {
    return this.http.get<any>(`${environment.apiUrl}/employee/blacklist-groups`);
  }

  saveAsList(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/save-as-list`, param);
  }

  saveAsBlacklist(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/save-as-blacklist`, param);
  }

  contactEmployee(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/contact-employee`, param);
  }

  sendNotificationToEmployee(param, id) {
    return this.http.post<any>(`${environment.apiUrl}/employee/send-notification-to-employee/${id}`, param);
  }

  contactEmployeeEmails(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/contact-employee-emails`, param);
  }

  getNotInListEmployees(groupId) {
    return this.http.get<any>(`${environment.apiUrl}/employee/not-list-employees/${groupId}`);
  }

  getNotInBlackListEmployees(groupId) {
    return this.http.get<any>(`${environment.apiUrl}/employee/not-black-list-employees/${groupId}`);
  }

  getEmployeeGroups(employeeId) {
    return this.http.get<any>(`${environment.apiUrl}/employee/groups/${employeeId}`);
  }
  
  getEmployeeBlacklistGroups(employeeId) {
    return this.http.get<any>(`${environment.apiUrl}/employee/blacklist-groups/${employeeId}`);
  }

  updateEmployeeGroups(param, employeeId) {
    return this.http.put<any>(`${environment.apiUrl}/employee/groups/${employeeId}`, param);
  }
  
  updateEmployeeBlacklistGroups(param, employeeId) {
    return this.http.put<any>(`${environment.apiUrl}/employee/blacklist-groups/${employeeId}`, param);
  }

  addMember(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/add-member`, param);
  }

  addBlackMember(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/add-black-member`, param);
  }

  addMemberToNewGroup(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/group-member`, param);
  }

  addListToNewGroup(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/group-list`, param);
  }

  addBlacklistToNewGroup(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/group-blacklist`, param);
  }

  checkIfListExists(name) {
    return this.http.get<any>(`${environment.apiUrl}/employee/list-exists?name=${name}`);
  }

  checkIfBlacklistExists(name) {
    return this.http.get<any>(`${environment.apiUrl}/employee/blacklist-exists?name=${name}`);
  }

  updateGroup(param, id) {
    return this.http.put<any>(`${environment.apiUrl}/employee/group/${id}`, param);
  }

  updateBlackGroup(param, id) {
    return this.http.put<any>(`${environment.apiUrl}/employee/black-list/${id}`, param);
  }

  deleteGroup(id) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/group/${id}`);
  }

  deleteBlackGroup(id) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/black-list/${id}`);
  }

  deactivateEmployee(id) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/deactivate/${id}`);
  }

  removeEmployeesFromGroups(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/remove-employees-from-groups`, param);
  }

  removeEmployeesFromBlackGroups(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/remove-employees-from-black-lists`, param);
  }

  getFilterTemplates() {
    return this.http.get<any>(`${environment.apiUrl}/project/filter-template`);
  }

  getFilter(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/filter/${id}`);
  }

  filteredEmployeesAmount(filter) {
    return this.http.post<any>(`${environment.apiUrl}/project/filtered-employee-number`, filter);
  }

  saveFilter(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/filter`, param);
  }

  updateFilter(param, filterId) {
    return this.http.put<any>(`${environment.apiUrl}/project/filter/${filterId}`, param);
  }

  getDashboardEmployees(page, orderColumn, orderAsc, name) {
    return this.getEmployees(page, orderColumn, orderAsc, name, null, []);
  }

  getPinwallEmployees(page, orderColumn, orderAsc, groupIds, name) {
    return this.getEmployees(page, orderColumn, orderAsc, name, null, groupIds);
  }

  getFilterEmployees(page, orderColumn = 'last_name', orderAsc, filter, name, blacklist = false) {
    return this.getEmployees(page, orderColumn, orderAsc, name, filter, [], blacklist);
  }

  // Profile

  getProfile(employee_id) {
    return this.http.get<any>(`${environment.apiUrl}/employee/profile/${employee_id}`);
  }

  getEmployeeJobHistory(employee_id, page = 1, orderColumn = null, orderAsc = null) {
    let orderString = '';

      if (orderColumn) {
          const direction = orderAsc ? 'asc' : 'desc';
          orderString = '&order_by=' + orderColumn + '&direction=' + direction;
      }

    return this.http.get<any>(`${environment.apiUrl}/employee/job-history/${employee_id}?page=${page + orderString}`);
  }

  getFutureJobs(employee_id, page = 1, orderColumn = null, orderAsc = null) {

      let orderString = '';

      if (orderColumn) {
          const direction = orderAsc ? 'asc' : 'desc';
          orderString = '&order_by=' + orderColumn + '&direction=' + direction;
      }
      return this.http.get<any>(`${environment.apiUrl}/employee/future-jobs/${employee_id}?page=${page + orderString}`);
  }

  getActionDashboard(page, search, orderBy = null, direction = null) {
    let sort = '';
    if(search) search = `&search=${search}`;
    if(orderBy) sort = `&order_by=${orderBy}&direction=${direction}`;

    return this.http.get<any>(`${environment.apiUrl}/hire/action-dashboard?page=${page}${search}${sort}`);
  }

  updateEmployee(param, employeeId) {
    return this.http.put<any>(`${environment.apiUrl}/employee/profile/${employeeId}`, param);
  }

  updateEmployeeListsChangedStatus(param, employeeId) {
    return this.http.put<any>(`${environment.apiUrl}/employee/profile-lists-changed-status/${employeeId}`, param);
  }

  updateEducation(param, employeeId) {
    return this.http.put<any>(`${environment.apiUrl}/employee/education/${employeeId}`, param);
  }

  deleteEducation(employeeId, educationId) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/education/${educationId}`, { params: { employee_id: employeeId } });
  }

  updateProfessionalExperience(param, employeeId) {
    return this.http.put<any>(`${environment.apiUrl}/employee/professional-experience/${employeeId}`, param);
  }

  deleteProfessionalExperience(employeeId, experienceId) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/professional-experience/${experienceId}`, { params: { employee_id: employeeId } });
  }

  updateLanguage(param, employeeId) {
    return this.http.put<any>(`${environment.apiUrl}/employee/language/${employeeId}`, param);
  }

  deleteLanguage(employeeId, languageId) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/language/${languageId}`, { params: { employee_id: employeeId } });
  }

  updateWeeklyAvailability(param, employeeId) {
    return this.http.put<any>(`${environment.apiUrl}/employee/weekly-availability/${employeeId}`, param);
  }

  removeFile(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/remove-file`, param);
  }

  deleteEducationFile(id) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/education-file/${id}`);
  }

  deleteLanguageFile(id) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/language-file/${id}`);
  }

  checkIBAN(iban) {
    return this.http.get<any>(`${environment.apiUrl}/employee/iban/${iban}`);
  }

  generatePdf(param) {
    return this.http.post<any>(`${environment.apiUrl}/pdf`, param)
  }

  generateXLSX(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/generate-xlsx`, param);
  }

  uploadImage( param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/upload-image`, param);
  }

  uploadFile( param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/upload-file`, param);
  }

  uploadEmployeeAgencyWorkAgreement( param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/upload-basic-agreement-file`, param);
  }

  uploadEmployeeLanguageFile( param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/language-file`, param);
  }

  uploadEmployeeEducationFile( param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/education-file`, param);
  }

  updateEmployeeStatus(employeeId, param) {
    return this.http.put<any>(`${environment.apiUrl}/employee/status/${employeeId}`, param);
  }

  getUnavailablePeriod(employee_id, page = 1, status = null, year = null, orderColumn = null, orderAsc = null) {
    let orderString = '';
    let filter = '';

      if (orderColumn) {
          const direction = orderAsc ? 'asc' : 'desc';
          orderString = '&order_by=' + orderColumn + '&direction=' + direction;
      }
      if (status && status.length) {
        filter += '&status=' + status;
      }
      if (year && year > 2000) {
        filter += '&year=' + year;
      }


    return this.http.get<any>(`${environment.apiUrl}/employee/unavailable-period/${employee_id}?page=${page + orderString + filter}&all=1`);
  }

  storeUnavailablePeriod(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/unavailable-period`, param);
  }

  updateUnavailablePeriod(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/employee/unavailable-period/${id}`, param);
  }

  approveUnavailablePeriod(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/employee/approve-unavailable-period/${id}`, param);
  }

  rejectUnavailablePeriod(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/employee/reject-unavailable-period/${id}`, param);
  }

  deleteUnavailablePeriod(id) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/unavailable-period/${id}`);
  }

  getAPC(eaf_id, orderColumn = null, orderAsc = null) {
    let orderString = '';
    if (orderColumn) {
        const direction = orderAsc ? 'asc' : 'desc';
        orderString = '&order_by=' + orderColumn + '&direction=' + direction;
    }
    return this.http.get<any>(`${environment.apiUrl}/employee/active-permanent-contract/${eaf_id}`);
  }

  storeAPC(param) {
    return this.http.post<any>(`${environment.apiUrl}/employee/active-permanent-contract`, param);
  }

  updateAPC(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/employee/active-permanent-contract/${id}`, param);
  }

  deleteAPC(id) {
    return this.http.delete<any>(`${environment.apiUrl}/employee/active-permanent-contract/${id}`);
  }

  storeCompensateHours(param) {
    return this.http.post<any>(`${environment.apiUrl}/agency/compensate-hours`, param);
  }

  getEmployeeCompensateHours(employeeId) {
    return this.http.get<any>(`${environment.apiUrl}/agency/compensate-hours/${employeeId}`);
  }

  updateCompensateHours(id, param) {
    return this.http.put<any>(`${environment.apiUrl}/agency/compensate-hours/${id}`, param);
  }

  deleteCompensateHours(id) {
    return this.http.delete<any>(`${environment.apiUrl}/agency/compensate-hours/${id}`);
  }

  updateLikedEmployee(body){
    return this.http.put<any>(`${environment.apiUrl}/employee/change-liked-employee`, body)
  }

  changeEmployeeRating(body){
    return this.http.put<any>(`${environment.apiUrl}/employee/change-employee-rating`, body)
  }

  addEmployeeComment(body){
    return this.http.put<any>(`${environment.apiUrl}/employee/add-employee-comment`, body)
  }

  addEmployeeToWishlist(body){
    return this.http.put<any>(`${environment.apiUrl}/employee/change-client-wishlist`, body)
  }

  getTempPassword(employeeId) {
    return this.http.get<any>(`${environment.apiUrl}/employee/temp-password/${employeeId}`,);
  }

  updateTempPassword(body) {
    return this.http.post<any>(`${environment.apiUrl}/employee/update-temp-password`, body);
  }
  
  canEmployeeEditIban() {
    return this.http.get<any>(`${environment.apiUrl}/can-employee-edit-iban`);
  }

  canConsultantEditIban(employeeId) {
    return this.http.get<any>(`${environment.apiUrl}/can-consultant-edit-iban/${employeeId}`);

  }
}
