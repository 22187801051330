import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class ProjectService {
  constructor(private http: HttpClient) {
  }

  show(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/show/${id}`);
  }
  showParentProjects() {
    return this.http.get<any>(`${environment.apiUrl}/project/show-parent-projects`);
  }

  showSimple(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/show-simple/${id}`);
  }

  storeProjectType(projectType) {
    return this.http.post<any>(`${environment.apiUrl}/project/project-type`, projectType);
  }

  update(client) {
    return this.http.put<any>(`${environment.apiUrl}/client/${client.id}`, client);
  }

  delete(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/client/${id}`);
  }

  index(page, orderColumn, orderAsc, tab = '', dateFrom = null, dateTo = null, admin_ids = null, project_type_ids = null, name = '', submissionDate = null) {
    let orderString = '';
    let nameString = '';
    let submissionDateString = '';

    if (orderColumn) {
      const direction = orderAsc ? 'asc' : 'desc';
      orderString = '&order_by=' + orderColumn + '&direction=' + direction;
    }

    if (name && name.length > 0) {
      nameString = '&name=' + name;
    }

    if (dateFrom) {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(dateFrom)) {
        dateFrom = dateFrom.getFullYear() + '-' + this.padMonthDay(dateFrom.getMonth() + 1) + '-' + this.padMonthDay(dateFrom.getDate());
      }
    } else {
      dateFrom = '';
    }
    
    if (dateTo) {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(dateTo)) {
        dateTo = dateTo.getFullYear() + '-' + this.padMonthDay(dateTo.getMonth() + 1) + '-' + this.padMonthDay(dateTo.getDate());
      }
    } else {
      dateTo = '';
    }
    
    let dateFromString = dateFrom ? '&from=' + dateFrom : '';
    let dateToString = dateTo ? '&to=' + dateTo : '';
    
    if (submissionDate) {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(submissionDate)) {
        submissionDate = submissionDate.getFullYear() + '-' + this.padMonthDay(submissionDate.getMonth() + 1) + '-' + this.padMonthDay(submissionDate.getDate());
      }
      submissionDateString = '&submissionDate=' + submissionDate;
    } else {
      submissionDateString = '';
    }

    if (!admin_ids) admin_ids = '';
    if (!project_type_ids) project_type_ids = '';
    return this.http.get<any>(`${environment.apiUrl}/project?page=${page + orderString + nameString + dateFromString + dateToString + submissionDateString}&tab=${tab}&admin_ids=${admin_ids}&project_type_ids=${project_type_ids}`);
  }
  
  countProjects(dateFrom = null, dateTo = null, admin_ids = null, project_type_ids = null) {
    if (dateFrom) {
      dateFrom = dateFrom.getFullYear() + '-' + this.padMonthDay(dateFrom.getMonth() + 1) + '-' + this.padMonthDay(dateFrom.getDate());
    } else {
      dateFrom = '';
    }
    if (dateTo) {
      dateTo = dateTo.getFullYear() + '-' + this.padMonthDay(dateTo.getMonth() + 1) + '-' + this.padMonthDay(dateTo.getDate());
    } else {
      dateTo = '';
    }

    if (!admin_ids) admin_ids = '';
    if (!project_type_ids) project_type_ids = '';
    return this.http.get<any>(`${environment.apiUrl}/project/count-projects?from=${dateFrom}&to=${dateTo}&admin_ids=${admin_ids}&project_type_ids=${project_type_ids}`);
  }

  getProjectsByType(type, dateFrom = null, dateTo = null, admin_ids = null, project_type_ids = null, page = 1,  orderColumn = null, orderAsc = null, name = null ) {
    let orderString = '';
    let nameString = '';

    if (orderColumn) {
      const direction = orderAsc ? 'asc' : 'desc';
      orderString = '&order_by=' + orderColumn + '&direction=' + direction;
    }

    if (name.length > 0) {
      nameString = '&name=' + name;
    }
    
    if (dateFrom) {
      dateFrom = dateFrom.getFullYear() + '-' + this.padMonthDay(dateFrom.getMonth() + 1) + '-' + this.padMonthDay(dateFrom.getDate());
    } else {
      dateFrom = '';
    }
    if (dateTo) {
      dateTo = dateTo.getFullYear() + '-' + this.padMonthDay(dateTo.getMonth() + 1) + '-' + this.padMonthDay(dateTo.getDate());
    } else {
      dateTo = '';
    }

    if (!admin_ids) admin_ids = '';
    return this.http.get<any>(`${environment.apiUrl}/project/type?page=${page + orderString + nameString}&type=${type}&from=${dateFrom}&to=${dateTo}&admin_ids=${admin_ids}`);
  }

  store(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/store`, param);
  }

  storeProjectExtrars(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/extras`, param);
  }

  storeAllocationExtrars(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/allocation-extras`, param);
  }

  getLocationsApi(param) {
    const url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' +
      param + '&types=geocode&country=ch&key=AIzaSyBM-5MzyZoh0hCJJtU0JrJCvcSbQR1f_44';

    return this.http.get(url);
  }

  placeDetailsApi(placeId): Observable<any> {
    const url = 'https://maps.googleapis.com/maps/api/place/details/json?placeid='
      + placeId + '&key=AIzaSyBM-5MzyZoh0hCJJtU0JrJCvcSbQR1f_44';
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getLocationsDB(param) {
    return this.http.get<any>(`${environment.apiUrl}/project/search-location?search=${param}`);
  }

  getLocationsGoogelMaps(param) {
    return this.http.post<any>(`${environment.apiUrl}/get-location-google-maps`, param);
  }

  storeLocation(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/storeLocation`, param);
  }

  storeClient(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/client`, param);
  }

  storeContact(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/contact`, param);
  }

  removeImage(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/image/remove`, param);
  }

  deleteLocationDate(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/delete-location-date/${id}`);
  }

  doesProjectLocationDateHaveAllocations(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/does-project-location-date-have-allocations/${id}`);
  }

  deleteProject(id) {
    return this.http.delete<any>(`${environment.apiUrl}/project/delete/${id}`);
  }

  getPublishes(id) {
    return this.http.get<any>(`${environment.apiUrl}/project/publish/${id}`);
  }

  publishProject(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/publish`, param);
  }

  getSubmittedTimestamps(page = 1, orderColumn, orderAsc, search, from, to, status, managedProjects, isClient = false) {
    let queryString = '';
    if (orderColumn) {
      const direction = orderAsc ? 'asc' : 'desc';
        queryString += '&order_by=' + orderColumn + '&direction=' + direction;
    }

    if (search) queryString += '&search=' + search;

    // if (name)  queryString += '&name=' + name;
    //
    // if (project)  queryString += '&project=' + project;
    //
    // if (role) queryString += '&role=' + role;

    if (from) queryString += '&from=' + from;

    if (to) queryString += '&to=' + to;

    if (status) queryString += '&status=' + status;

    if (isClient) queryString += '&is_client=' + isClient;

    queryString += '&managed_projects=' + managedProjects;

    return this.http.get<any>(`${environment.apiUrl}/hire/submitted-timestamps?page=${page + queryString}`);
  }

  padMonthDay(number) {
    if (number < 10) {
      return '0' + number;
    } else {
      return number;
    }
  }

  getAgencyLogoPath(id) {
      return this.http.get<any>(`${environment.apiUrl}/agency/logo/${id}`);
  }

  cancelProject(id) {
      return this.http.delete<any>(`${environment.apiUrl}/project/cancel/${id}`);
  }

  generatePastJobsXLSX(param) {
      return this.http.post<any>(`${environment.apiUrl}/hire/generate-xlsx`, param);
  }

  exportWorkValidationDashboardAsXLSX(orderColumn, orderAsc, search, from, to, status) {
      let queryString = '';
      if (orderColumn) {
          const direction = orderAsc ? 'asc' : 'desc';
          queryString += '&order_by=' + orderColumn + '&direction=' + direction;
      }

      if (from) queryString += '&from=' + from;

      if (to) queryString += '&to=' + to;

      if (status) queryString += '&status=' + status;

      return this.http.get<any>(`${environment.apiUrl}/hire/export-submitted-timestamps?search=${search + queryString}`);
  }

  exportProjectCostOverview(id) {
      return this.http.get<any>(`${environment.apiUrl}/project/cost-overview/${id}`);
  }

  schedulePublication(param) {
    return this.http.post<any>(`${environment.apiUrl}/project/schedule-publication`, param);
  }

  getScheduledPublications(id) {
      return this.http.get<any>(`${environment.apiUrl}/project/scheduled-publications/${id}`);
  }

  deleteScheduledPublication(scheduleId) {
      return this.http.delete<any>(`${environment.apiUrl}/project/scheduled-publications/${scheduleId}`);
  }

  checkIfProjectNumberExists(projectId, number) {
    return this.http.get<any>(`${environment.apiUrl}/project/number-exists/${projectId}/${number}`);
  }

  sendDataToClient(model){
    return this.http.post<any>(`${environment.apiUrl}/send-data-to-client`, model);
  }
  autoAssignMissions(id, params = null){
    return this.http.post<any>(`${environment.apiUrl}/project/auto-assign-missions/${id}`, params)
  }
}
