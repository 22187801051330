import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {PermissionService} from '../_services';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RedirectingGuard implements CanActivate {

  constructor(private router: Router, private permissionService: PermissionService, private messageService: MessageService, private translate: TranslateService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (!currentUser) {
      this.router.navigate(['login']);
      return true;
    } else if (currentUser.role === 'employee') {
      this.router.navigate(['profile']);
      return true;
    } else {
      this.permissionService.sidebarPermissions().subscribe(response => {
        let redirectRoute = '';
        if  (response.projects) {
          redirectRoute = '/project';
        } else if (response.employees) {
          redirectRoute = '/employee';
        } else if (response.agency_profile) {
          redirectRoute = '/agency/profile/' + currentUser.agency_id;
        } else if (response.agency_client) {
          redirectRoute = '/agency-client/profile/' + currentUser.agency_id;
        } else if(response.orders) {
          redirectRoute = '/orders'
        } else if(response.client_work_validation) {
          redirectRoute = '/client-work-validation/dashboard'
        } else {
          this.translate.get('You do not have permissions continue. Redirecting to login...').subscribe(res => {
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('Error!'),
              detail: res
            });
            localStorage.removeItem('currentUser');
            redirectRoute = '/login';
          });
        }
        this.router.navigate([redirectRoute]);
        return true;
      });
    }
  }
}
