export class DropdownOptions {
    static cantons = [
        {label: 'AG', value: 'AG'},
        {label: 'AI', value: 'AI'},
        {label: 'AR', value: 'AR'},
        {label: 'BE', value: 'BE'},
        {label: 'BL', value: 'BL'},
        {label: 'BS', value: 'BS'},
        {label: 'FR', value: 'FR'},
        {label: 'GE', value: 'GE'},
        {label: 'GL', value: 'GL'},
        {label: 'GR', value: 'GR'},
        {label: 'JU', value: 'JU'},
        {label: 'LU', value: 'LU'},
        {label: 'NE', value: 'NE'},
        {label: 'NW', value: 'NW'},
        {label: 'OW', value: 'OW'},
        {label: 'SG', value: 'SG'},
        {label: 'SH', value: 'SH'},
        {label: 'SO', value: 'SO'},
        {label: 'SZ', value: 'SZ'},
        {label: 'TG', value: 'TG'},
        {label: 'TI', value: 'TI'},
        {label: 'UR', value: 'UR'},
        {label: 'VD', value: 'VD'},
        {label: 'VS', value: 'VS'},
        {label: 'ZG', value: 'ZG'},
        {label: 'ZH', value: 'ZH'}
    ];

    static countries = [
        {label: 'afghanistan', value: 'afghanistan'},
        {label: 'albania', value: 'albania'},
        {label: 'algeria', value: 'algeria'},
        {label: 'andorra', value: 'andorra'},
        {label: 'angola', value: 'angola'},
        {label: 'antigua and barbuda ', value: 'antigua and barbuda'},
        {label: 'argentina', value: 'argentina'},
        {label: 'armenia', value: 'armenia'},
        {label: 'australia', value: 'australia'},
        {label: 'austria', value: 'austria'},
        {label: 'azerbaijan', value: 'azerbaijan'},
        {label: 'anguilla', value: 'anguilla'},
        {label: 'netherlands antilles', value: 'netherlands antilles'}, 
        {label: 'antarctica', value: 'antarctica'},
        {label: 'aruba', value: 'aruba'},
        {label: 'bahamas', value: 'bahamas'},
        {label: 'bahrain', value: 'bahrain'},
        {label: 'bangladesh', value: 'bangladesh'},
        {label: 'barbados', value: 'barbados'},
        {label: 'belarus', value: 'belarus'},
        {label: 'belgium', value: 'belgium'},
        {label: 'belize', value: 'belize'},
        {label: 'benin', value: 'benin'},
        {label: 'bhutan', value: 'bhutan'},
        {label: 'bolivia', value: 'bolivia'},
        {label: 'bosnia and herzegovina', value: 'bosnia and herzegovina'},
        {label: 'botswana', value: 'botswana'},
        {label: 'brazil', value: 'brazil'},
        {label: 'brunei', value: 'brunei'},
        {label: 'bulgaria', value: 'bulgaria'},
        {label: 'burkina faso', value: 'burkina faso'},
        {label: 'burundi', value: 'burundi'},
        {label: 'bermuda', value: 'bermuda'},
        {label: 'bouvet island', value: 'bouvet island'},
        {label: 'british indian ocean territory', value: 'british indian ocean territory'},
        {label: 'british virgin islands', value: 'british virgin islands'},
        {label: 'cabo verde', value: 'cabo verde'},
        {label: 'cambodia', value: 'cambodia'},
        {label: 'cameroon', value: 'cameroon'},
        {label: 'canada', value: 'canada'},
        {label: 'central african republic', value: 'central african republic'},
        {label: 'chad', value: 'chad'},
        {label: 'chile', value: 'chile'},
        {label: 'china', value: 'china'},
        {label: 'colombia', value: 'colombia'},
        {label: 'comoros', value: 'comoros'},
        {label: 'congo, democratic republic of the', value: 'congo, democratic republic of the'},
        {label: 'congo, republic of the', value: 'congo, republic of the'},
        {label: 'costa rica', value: 'costa rica'},
        {label: 'côte d’ivoire', value: 'côte d’ivoire'},
        {label: 'croatia', value: 'croatia'},
        {label: 'cuba', value: 'cuba'},
        {label: 'cyprus', value: 'cyprus'},
        {label: 'czech republic', value: 'czech republic'},
        {label: 'cocos island', value: 'cocos island'},
        {label: 'cook islands', value: 'cook islands'},
        {label: 'christmas islands', value: 'christmas islands'},
        {label: 'denmark', value: 'denmark'},
        {label: 'djibouti', value: 'djibouti'},
        {label: 'dominica', value: 'dominica'},
        {label: 'dominican republic', value: 'dominican republic'},
        {label: 'east timor (timor-leste)', value: 'east timor (timor-leste)'},
        {label: 'ecuador', value: 'ecuador'},
        {label: 'egypt', value: 'egypt'},
        {label: 'el salvador', value: 'el salvador'},
        {label: 'equatorial guinea', value: 'equatorial guinea'},
        {label: 'eritrea', value: 'eritrea'},
        {label: 'estonia', value: 'estonia'},
        {label: 'ethiopia', value: 'ethiopia'},
        {label: 'fiji', value: 'fiji'},
        {label: 'finland', value: 'finland'},
        {label: 'france', value: 'france'},
        {label: 'falkland islands', value: 'falkland islands'},
        {label: 'french guiana', value: 'french guiana'},
        {label: 'french polynesia', value: 'french polynesia'},
        {label: 'french southern territories', value: 'french southern territories'},
        {label: 'gabon', value: 'gabon'},
        {label: 'gambia', value: 'gambia'},
        {label: 'georgia', value: 'georgia '},
        {label: 'germany', value: 'germany'},
        {label: 'ghana', value: 'ghana'},
        {label: 'greece', value: 'greece'},
        {label: 'grenada', value: 'grenada'},
        {label: 'guatemala', value: 'guatemala'},
        {label: 'guinea', value: 'guinea'},
        {label: 'guinea-bissau', value: 'guinea-bissau'},
        {label: 'guyana', value: 'guyana'},
        {label: 'gibraltar', value: 'gibraltar'},
        {label: 'guadeloupe', value: 'guadeloupe'},
        {label: 'greenland', value: 'greenland'},
        {label: 'guam', value: 'guam'},
        {label: 'haiti', value: 'haiti'},
        {label: 'honduras', value: 'honduras'},
        {label: 'hungary', value: 'hungary'},
        {label: 'hong kong', value: 'hong kong'},
        {label: 'heard and mcDonald islands', value: 'heard and mcDonald islands'},
        {label: 'iceland', value: 'iceland'},
        {label: 'india', value: 'india'},
        {label: 'indonesia', value: 'indonesia'},
        {label: 'iran', value: 'iran'},
        {label: 'iraq', value: 'iraq'},
        {label: 'ireland', value: 'ireland'},
        {label: 'israel', value: 'israel'},
        {label: 'italy', value: 'italy'},
        {label: 'jamaica', value: 'jamaica'},
        {label: 'japan', value: 'japan'},
        {label: 'jordan', value: 'jordan'},
        {label: 'kazakhstan', value: 'kazakhstan'},
        {label: 'kenya', value: 'kenya'},
        {label: 'kiribati', value: 'kiribati'},
        {label: 'korea, north', value: 'korea, north'},
        {label: 'korea, south', value: 'norea, south'},
        {label: 'kosovo', value: 'kosovo'},
        {label: 'kuwait', value: 'kuwait'},
        {label: 'kyrgyzstan', value: 'kyrgyzstan'},
        {label: 'laos', value: 'laos'},
        {label: 'latvia', value: 'latvia'},
        {label: 'lebanon', value: 'lebanon'},
        {label: 'lesotho', value: 'lesotho'},
        {label: 'liberia', value: 'liberia'},
        {label: 'libya', value: 'libya'},
        {label: 'liechtenstein', value: 'liechtenstein'},
        {label: 'lithuania', value: 'lithuania'},
        {label: 'luxembourg', value: 'luxembourg'},
        {label: 'madagascar', value: 'madagascar'},
        {label: 'malawi', value: 'malawi'},
        {label: 'malaysia', value: 'malaysia'},
        {label: 'maldives', value: 'maldives'},
        {label: 'mali', value: 'mali'},
        {label: 'malta', value: 'malta'},
        {label: 'marshall islands', value: 'marshall islands'},
        {label: 'mauritania', value: 'mauritania'},
        {label: 'mauritius', value: 'mauritius'},
        {label: 'mexico', value: 'mexico'},
        {label: 'micronesia, federated states of', value: 'micronesia, federated states of'},
        {label: 'moldova', value: 'moldova'},
        {label: 'monaco', value: 'monaco'},
        {label: 'mongolia', value: 'mongolia'},
        {label: 'montenegro', value: 'montenegro'},
        {label: 'morocco', value: 'morocco'},
        {label: 'mozambique', value: 'mozambique'},
        {label: 'myanmar (Burma)', value: 'myanmar (burma)'},
        {label: 'macau', value: 'macau'},
        {label: 'martinique', value: 'martinique'},
        {label: 'montserrat', value: 'montserrat'},
        {label: 'mayotte', value: 'mayotte'},
        {label: 'namibia', value: 'namibia'},
        {label: 'nauru', value: 'nauru'},
        {label: 'nepal', value: 'nepal'},
        {label: 'netherlands', value: 'netherlands'},
        {label: 'new zealand', value: 'new zealand'},
        {label: 'nicaragua', value: 'nicaragua'},
        {label: 'niger', value: 'niger'},
        {label: 'nigeria', value: 'nigeria'},
        {label: 'north macedonia', value: 'north macedonia'},
        {label: 'norway', value: 'norway'},
        {label: 'northern mariana islands', value: 'northern mariana islands'},
        {label: 'new caledonia', value: 'new caledonia'},
        {label: 'norfolk island', value: 'norfolk island'},
        {label: 'oman', value: 'oman'},
        {label: 'pakistan', value: 'pakistan'},
        {label: 'palau', value: 'palau'},
        {label: 'panama', value: 'panama'},
        {label: 'papua new guinea', value: 'papua new guinea'},
        {label: 'paraguay', value: 'paraguay'},
        {label: 'peru', value: 'peru'},
        {label: 'philippines', value: 'philippines'},
        {label: 'poland', value: 'poland'},
        {label: 'portugal', value: 'portugal'},
        {label: 'pitcairn islands', value: 'pitcairn islands'},
        {label: 'puerto rico', value: 'puerto rico'},
        {label: 'palestine', value: 'palestine'},
        {label: 'qatar', value: 'qatar'},
        {label: 'romania', value: 'romania'},
        {label: 'russia', value: 'russia'},
        {label: 'rwanda', value: 'rwanda'},
        {label: 'republic of the congo', value: 'republic of the congo'},
        {label: 'Réunion', value: 'Réunion'},
        {label: 'saint kitts and nevis', value: 'saint kitts and nevis'},
        {label: 'saint lucia', value: 'saint lucia'},
        {label: 'saint vincent and the grenadines', value: 'saint vincent and the grenadines'},
        {label: 'samoa', value: 'samoa'},
        {label: 'san marino', value: 'san marino'},
        {label: 'sao tome and principe', value: 'sao tome and principe'},
        {label: 'saudi arabia', value: 'saudi arabia'},
        {label: 'senegal', value: 'senegal'},
        {label: 'serbia', value: 'serbia'},
        {label: 'seychelles', value: 'seychelles'},
        {label: 'sierra leone', value: 'sierra leone'},
        {label: 'singapore', value: 'singapore'},
        {label: 'slovakia', value: 'slovakia'},
        {label: 'slovenia', value: 'slovenia'},
        {label: 'solomon islands', value: 'solomon islands'},
        {label: 'somalia', value: 'somalia'},
        {label: 'south africa', value: 'south africa'},
        {label: 'spain', value: 'spain'},
        {label: 'sri lanka', value: 'sri lanka'},
        {label: 'sudan, south', value: 'sudan, south'},
        {label: 'sudan', value: 'sudan'},
        {label: 'suriname', value: 'suriname'},
        {label: 'swaziland', value: 'swaziland'},
        {label: 'sweden', value: 'sweden'},
        {label: 'switzerland', value: 'switzerland'},
        {label: 'syria', value: 'syria'},
        {label: 'south georgia and the south sandwich islands', value: 'south georgia and the south sandwich islands'},
        {label: 'saint pierre and miquelon', value: 'saint pierre and miquelon'},
        {label: 'saint helena', value: 'saint helena'},
        {label: 'svalbard and jan mayen', value: 'svalbard and jan mayen'},
        {label: 'taiwan', value: 'taiwan'},
        {label: 'tajikistan', value: 'tajikistan'},
        {label: 'tanzania', value: 'tanzania'},
        {label: 'thailand', value: 'thailand'},
        {label: 'togo', value: 'togo'},
        {label: 'tonga', value: 'tonga'},
        {label: 'trinidad and tobago', value: 'yrinidad and tobago'},
        {label: 'tunisia', value: 'tunisia'},
        {label: 'turkey', value: 'turkey'},
        {label: 'turkmenistan', value: 'turkmenistan'},
        {label: 'tuvalu', value: 'tuvalu'},
        {label: 'turks and caicos islands', value: 'turks and caicos islands'},
        {label: 'tibet', value: 'tibet'},
        {label: 'tokelau', value: 'tokelau'},
        {label: 'uganda', value: 'uganda'},
        {label: 'ukraine', value: 'ukraine'},
        {label: 'united arab emirates', value: 'united arab emirates'},
        {label: 'united kingdom', value: 'united kingdom'},
        {label: 'united states', value: 'united states'},
        {label: 'uruguay', value: 'uruguay'},
        {label: 'uzbekistan', value: 'uzbekistan'},
        {label: 'united utates minor outlying islands', value: 'united utates minor outlying islands'},
        {label: 'vanuatu', value: 'vanuatu'},
        {label: 'vatican city', value: 'vatican city'},
        {label: 'venezuela', value: 'venezuela'},
        {label: 'vietnam', value: 'vietnam'},
        {label: 'western sahara', value: 'western sahara'},
        {label: 'wallis and futuna', value: 'wallis and futuna'},
        {label: 'yemen', value: 'yemen'},
        {label: 'zambia', value: 'zambia'},
        {label: 'zimbabwe', value: 'zimbabwe'},
        {label: 'zzstateless', value: 'zzstateless'},
        {label: 'zzunknown', value: 'zzunknown'},
    ];

    static gender = [
        {label: 'male', value: 'male'},
        {label: 'female', value: 'female'},
        {label: 'other', value: 'other'}
    ];

    static statusValidations = [
        {label: 'pending', value: 'pending'},
        {label: 'valid', value: 'valid'},
        {label: 'completed', value: 'completed'}
    ];

    static eyeColor = [
        {label: 'amber', value: 'amber'},
        {label: 'blue', value: 'blue'},
        {label: 'brown', value: 'brown'},
        {label: 'gray', value: 'gray'},
        {label: 'green', value: 'green'},
        {label: 'hazel', value: 'hazel'},
        {label: 'other', value: 'other'},
    ];

    static hairColor = [
        {label: 'blond', value: 'blond'},
        {label: 'sandy', value: 'sandy'},
        {label: 'strawberry', value: 'strawberry'},
        {label: 'red', value: 'red'},
        {label: 'auburn', value: 'auburn'},
        {label: 'light brown', value: 'light brown'},
        {label: 'brown', value: 'brown'},
        {label: 'dark brown', value: 'dark brown'},
        {label: 'black', value: 'black'},
        {label: 'gray', value: 'gray'},
        {label: 'white', value: 'white'},
        {label: 'bald', value: 'bald'},
        {label: 'green', value: 'green'},
        {label: 'blue', value: 'blue'},
        {label: 'orange', value: 'orange'},
        {label: 'pink', value: 'pink'},
        {label: 'purple', value: 'purple'},
        {label: 'other', value: 'other'},
    ];

    static ethnicity = [
        {label: 'european (caucasoid)', value: 'european'},
        {label: 'arab (caucasoid)', value: 'arab'},
        {label: 'central asian (caucasoid-mongoloid)', value: 'central asian'},
        {label: 'indian (caucasoid-australoid)', value: 'indian'},
        {label: 'malagasy (caucasoid-australoid-black)', value: 'malagasy'},
        {label: 'indian-black american', value: 'indian-black american'},
        {label: 'mulatto (black-european)', value: 'mulatto'},
        {label: 'black (capoid-congoid)', value: 'black'},
        {label: 'east asian (mongoloid)', value: 'east asian'},
        {label: 'inuit (mongoloid)', value: 'inuit'},
        {label: 'amerindian (mongoloid)', value: 'amerindian'},
        {label: 'mestizo (amerindian-european)', value: 'mestizo'},
        {label: 'american multiracial (amerindinan, european, black)', value: 'american multiracial'},
        {label: 'australasian multiracial (australoid-european)', value: 'australasian multiracial'},
        {label: 'australoid', value: 'australoid'}
    ];

    static shoeSize = [
        {label: '36', value: '36'},
        {label: '37', value: '37'},
        {label: '38', value: '38'},
        {label: '39', value: '39'},
        {label: '40', value: '40'},
        {label: '41', value: '41'},
        {label: '42', value: '42'},
        {label: '43', value: '43'},
        {label: '44', value: '44'},
        {label: '45', value: '45'},
        {label: '46', value: '46'},
        {label: '47', value: '47'},
        {label: '48', value: '48'},
        {label: '49', value: '49'},
        {label: '50', value: '50'},
        {label: '51', value: '51'},
        {label: '52', value: '52'},
    ];

    static trouserWidth = [
        {label: '23 - XS', value: '23'},
        {label: '24 - XS', value: '24'},
        {label: '25 - XS', value: '25'},
        {label: '26 - S', value: '26'},
        {label: '27 - S', value: '27'},
        {label: '28 - S', value: '28'},
        {label: '29 - M', value: '29'},
        {label: '30 - M', value: '30'},
        {label: '31 - M', value: '31'},
        {label: '32 - L', value: '32'},
        {label: '33 - L', value: '33'},
        {label: '34 - L', value: '34'},
        {label: '35 - XL', value: '35'},
        {label: '36 - XL', value: '36'},
        {label: '37 - XXL', value: '37'},
        {label: '38 - XXL', value: '38'},
        {label: '40 - XXL', value: '40'},
        {label: '42 - 3XL', value: '42'},
        {label: '44 - 4XL', value: '44'},
        {label: '46 - 4XL', value: '46'},
        {label: '48 - 5XL', value: '48'},
        {label: '50 - 6XL', value: '50'},
        {label: '52 - 6XL', value: '52'},
        {label: '54 - 7XL', value: '54'},
        {label: '56 - 8XL', value: '56'},
        {label: '58 - 8XL', value: '58'},
    ];

    static trouserWidthOrLength = [
        {label: '28', value: '28'},
        {label: '30', value: '30'},
        {label: '31', value: '31'},
        {label: '32', value: '32'},
        {label: '33', value: '33'},
        {label: '34', value: '34'},
        {label: '35', value: '35'},
        {label: '36', value: '36'},
        {label: '37', value: '37'},
        {label: '38', value: '38'},
    ];

    static shirtSize = [
        {label: 'XXS', value: '8'},
        {label: 'XS', value: '1'},
        {label: 'S', value: '2'},
        {label: 'M', value: '3'},
        {label: 'L', value: '4'},
        {label: 'XL', value: '5'},
        {label: 'XXL', value: '6'},
        {label: '3XL', value: '7'},
    ];

    static education = [
      
        {label: 'apprenticeship', value: 'apprenticeship'},
        {label: 'high school diploma', value: 'high school diploma'},
        {label: 'vocational diploma', value: 'vocational diploma'},
        {label: 'bachelor', value: 'bachelor'},
        {label: 'master', value: 'master'},
        {label: 'doctorate', value: 'doctorate'},
        {label: 'further education', value: 'further education'},
        {label: 'other education', value: 'other education'},
    ];

    static professionExperience = [
      {
        label: 'Catering, hotel industry, tourism, food',
        items: [
            {label: 'kitchen', value: 'kitchen'},
            {label: 'service / bar', value: 'service / bar'},
            {label: 'rooms / lingerie / housekeeping', value: 'rooms / lingerie / housekeeping'},
            {label: 'reception / porter / concierge', value: 'reception / porter / concierge'},
            {label: 'travel advice / tour guide', value: 'travel advice / tour guide'},
            {label: 'business management / administration', value: 'business management / administration'},
            {label: 'others catering, hotel industry, tourism, food', value: 'others catering, hotel industry, tourism, food'}
        ]
    },
    {
        label: 'Medicine, Healthcare, Nursing',
        items: [
          {label: 'acute care', value: 'acute care'},
          {label: 'long-term care', value: 'long-term care'},
          {label: 'medical practice assistance', value: 'medical practice assistance'},
          {label: 'therapy / laboratory / analysis', value: 'therapy / laboratory / analysis'},
          {label: 'doctors - assistant/specialist/senior physicians', value: 'doctors - assistant/specialist/senior physicians'},
          {label: 'others medicine, healthcare, nursing', value: 'others medicine, healthcare, nursing'}

        ]
    },
    {
        label: 'Commercial Professions, Customer Service, Administration',
        items: [
          {label: 'secretariat / reception', value: 'secretariat / reception'},
          {label: 'assistance', value: 'assistance'},
          {label: 'administration', value: 'administration'},
          {label: 'translation', value: 'translation'},
          {label: 'management', value: 'management'},
          {label: 'others commercial professions, customer service, administration', value: 'others commercial professions, customer service, administration'}
        ]
    },
    {
        label: 'Facility Management, Cleaning, Warehouse',
        items: [
          {label: 'facility management / cleaning', value: 'facility management / cleaning'},
          {label: 'warehouse', value: 'warehouse'},
          {label: 'others facility management, cleaning, warehouse', value: 'others facility management, cleaning, warehouse'}                  
        ]
    },
    {
        label: 'Construction, Electrical, Sanitary',
        items: [
          {label: 'construction personnel / scaffolding / machinery', value: 'construction personnel / scaffolding / machinery'},
          {label: 'floor layers / panellers', value: 'floor layers / panellers'},
          {label: 'painters / plasterers / bricklayers', value: 'painters / plasterers / bricklayers'},
          {label: 'electrical planning / assembly', value: 'electrical planning / assembly'},
          {label: 'plumbing / heating / ventilation / air conditioning', value: 'plumbing / heating / ventilation / air conditioning'},
          {label: 'others construction, electrical, sanitary', value: 'others construction, electrical, sanitary'}                  
        ]
    },
    {
        label: 'Administration, Education, Social Services',
        items: [
          {label: 'public administration', value: 'public administration'},
          {label: 'science / research', value: 'science / research'},
          {label: 'teachers / lecturers', value: 'teachers / lecturers'},
          {label: 'social / remedial teachers', value: 'social / remedial teachers'},
          {label: 'social / youth work', value: 'social / youth work'},
          {label: 'NGO / NPO / aid organisations', value: 'NGO / NPO / aid organisations'},
          {label: 'others administration, education, social services', value: 'others administration, education, social services'}                  
        ]
    },
    {
        label: 'Sales, Customer Service',
        items: [
          {label: 'telephone sales / telesales', value: 'telephone sales / telesales'},
          {label: 'retail sales', value: 'retail sales'},
          {label: 'field sales / specialists', value: 'field sales / specialists'},
          {label: 'internal sales / back office', value: 'internal sales / back office'},
          {label: 'customer counselling / key account management', value: 'customer counselling / key account management'},
          {label: 'others sales, customer service', value: 'others sales, customer service'}                  
        ]
    },
    {
        label: 'Craft, Vehicles, Agriculture and Forestry',
        items: [
          {label: 'metalworking', value: 'metalworking'},
          {label: 'woodworking', value: 'woodworking'},
          {label: 'textiles / tailoring', value: 'textiles / tailoring'},
          {label: 'vehicle mechanics/diagnostics', value: 'vehicle mechanics/diagnostics'},
          {label: 'vehicle body carpenters / painters', value: 'vehicle body carpenters / painters'},
          {label: 'horticulture / landscape architecture', value: 'horticulture / landscape architecture'},
          {label: 'agriculture / forestry', value: 'agriculture / forestry'},
          {label: 'others craft, vehicles, agriculture and forestry', value: 'others craft, vehicles, agriculture and forestry'}                  
        ]
    },
    {
        label: 'Purchasing, Logistics, Transport, Traffic',
        items: [
          {label: 'purchasing / procurement', value: 'purchasing / procurement'},
          {label: 'logistics / supply chain', value: 'logistics / supply chain'},
          {label: 'import / export / trading', value: 'import / export / trading'},
          {label: 'passenger transport by air / sea / train / road', value: 'passenger transport by air / sea / train / road'},
          {label: 'others purchasing, logistics, transport, traffic', value: 'others purchasing, logistics, transport, traffic'}                  
        ]
    },
    {
        label: 'Culture, Sport, Wellness',
        items: [
          {label: 'hairdresser / cosmetics', value: 'hairdresser / cosmetics'},
          {label: 'massage / sport / wellness', value: 'massage / sport / wellness'},
          {label: 'culture / entertainment', value: 'culture / entertainment'},
          {label: 'museum / art / handicrafts', value: 'museum / art / handicrafts'},
          {label: 'drama / dance', value: 'drama / dance'},
          {label: 'fashion / film / theatre / radio', value: 'fashion / film / theatre / radio'},
          {label: 'others culture, sport, wellness', value: 'others culture, sport, wellness'}                  
        ]
    },
    {
        label: 'Security / Police / Customs / Rescue',
        items: [
          {label: 'police / military', value: 'police / military'},
          {label: 'rescue / ambulance / fire brigade', value: 'rescue / ambulance / fire brigade'},
          {label: 'security / control / guarding', value: 'security / control / guarding'},
          {label: 'customs / border control', value: 'customs / border control'},
          {label: 'others security, police, customs, rescue', value: 'others security, police, customs, rescue'}                  
        ]
    },
    {
        label: 'Information Technology, Telecommunications',
        items: [
          {label: 'software development', value: 'software development'},
          {label: 'network / system administration', value: 'network / system administration'},
          {label: 'audit / security', value: 'audit / security'},
          {label: 'data analysis/management', value: 'data analysis/management'},
          {label: 'IT consulting / IT services', value: 'IT consulting / IT services'},
          {label: 'others information technology, telecommunications', value: 'others information technology, telecommunications'}                  
        ]
    },
    {
        label: 'Architecture',
        items: [
          {label: 'architecture / building planning / draughtsman', value: 'architecture / building planning / draughtsman'},
          {label: 'interior design / decoration', value: 'interior design / decoration'},
          {label: 'others architecture', value: 'others architecture'}                  
        ]
    },
    {
        label: 'Bank, Insurance',
        items: [
          {label: 'customer consulting / acquisition / relationship management', value: 'customer consulting / acquisition / relationship management'},
          {label: 'risk management / compliance', value: 'risk management / compliance'},
          {label: 'treasury / controlling / audit', value: 'treasury / controlling / audit'},
          {label: 'others bank, insurance', value: 'others bank, insurance'}                  
        ]
    },
    {
        label: 'Chemistry, Pharmaceuticals, Biotechnology',
        items: [
          {label: 'pharmacies / drugstores', value: 'pharmacies / drugstores'},
          {label: 'biology / biotechnology', value: 'biology / biotechnology'},
          {label: 'chemistry R&D / analytics / production', value: 'chemistry R&D / analytics / production'},
          {label: 'pharma R&D / analytics / production', value: 'pharma R&D / analytics / production'},
          {label: 'quality assurance / management', value: 'quality assurance / management'},
          {label: 'others chemistry, pharmaceuticals, biotechnology', value: 'others chemistry, pharmaceuticals, biotechnology'}                  
        ]
    },
    {
        label: 'Consulting, Human Resources, Legal',
        items: [
          {label: 'consultancy / corporate development', value: 'consultancy / corporate development'},
          {label: 'lawyers / attorneys / court', value: 'lawyers / attorneys / court'},
          {label: 'human resources / development', value: 'human resources / development'},
          {label: 'recruitment / consultancy', value: 'recruitment / consultancy'},
          {label: 'others consulting, human resources, legal', value: 'others consulting, human resources, legal'}                  
        ]
    },
    {
        label: 'Finance, Fiduciary, Real Estate',
        items: [
          {label: 'audit / revision', value: 'audit / revision'},
          {label: 'finance / accounting / controlling', value: 'finance / accounting / controlling'},
          {label: 'real estate trading/management', value: 'real estate trading/management'},
          {label: 'payroll accounting / taxes', value: 'payroll accounting / taxes'},
          {label: 'fiduciary services', value: 'fiduciary services'},
          {label: 'others finance, fiduciary, real estate', value: 'others finance, fiduciary, real estate'}                  
        ]
    },
    {
        label: 'Industry, Mechanical Engineering, Plant Construction, Production',
        items: [
          {label: 'plant/apparatus engineering', value: 'plant/apparatus engineering'},
          {label: 'automation / process engineering', value: 'automation / process engineering'},
          {label: 'construction', value: 'construction'},
          {label: 'CNC mechanics / polymechanics', value: 'CNC mechanics / polymechanics'},
          {label: 'mechanical engineering', value: 'mechanical engineering'},
          {label: 'production / Manufacturing / Assembly', value: 'production / Manufacturing / Assembly'},
          {label: 'service engineering / maintenance', value: 'service engineering / maintenance'},
          {label: 'others industry, mechanical engineering, plant construction, production', value: 'others industry, mechanical engineering, plant construction, production'}                  
        ]
    },
    {
        label: 'Marketing, Communication, Media, Printing',
        items: [
          {label: 'marketing / direct marketing', value: 'marketing / direct marketing'},
          {label: 'PR / corporate communications', value: 'PR / corporate communications'},
          {label: 'media / editing / publishing', value: 'media / editing / publishing'},
          {label: 'printers', value: 'printers'},
          {label: 'graphics / design', value: 'graphics / design'},
          {label: 'others marketing, communication, media, printing', value: 'others marketing, communication, media, printing'}                  
        ]
    },
    {
        label: 'Mechanics, Electronics',
        items: [
          {label: 'electronics / electrical engineering', value: 'electronics / electrical engineering'},
          {label: 'mechanics / precision mechanics / watches', value: 'mechanics / precision mechanics / watches'},
          {label: 'toolmaking', value: 'toolmaking'},
          {label: 'medical technology', value: 'medical technology'},
          {label: 'energy / building / building services engineering', value: 'energy / building / building services engineering'},
          {label: 'customer service / consulting / service engineering', value: 'customer service / consulting / service engineering'},
          {label: 'others mechanics, electronics', value: 'others mechanics, electronics'}                  
        ]
    },
    {
        label: 'other',
        items: [
            {label: 'other', value: 'other'},
        ]
    }
  ];

  static professionalExperience = [
      {label: 'employed with professional experience', value: 'employed with professional experience'},
      {label: 'employed without professional experience', value: 'employed without professional experience'},
      {label: 'self-employed', value: 'self-employed'},
      {label: 'working student', value: 'working student'},
      {label: 'internship', value: 'internship'},
      {label: 'volunteer work', value: 'volunteer work'},
      {label: 'other professional experience', value: 'other professional experience'}
  ];

  static groupExperience = [
    {label: 'Catering, hotel industry, tourism, food', value: 'Catering, hotel industry, tourism, food'},
    {label: 'Medicine, Healthcare, Nursing', value: 'Medicine, Healthcare, Nursing'},
    {label: 'Commercial Professions, Customer Service, Administration', value: 'Commercial Professions, Customer Service, Administration'},
    {label: 'Facility Management, Cleaning, Warehouse', value: 'Facility Management, Cleaning, Warehouse'},
    {label: 'Construction, Electrical, Sanitary', value: 'Construction, Electrical, Sanitary'},
    {label: 'Administration, Education, Social Services', value: 'Administration, Education, Social Services'},
    {label: 'Sales, Customer Service', value: 'Sales, Customer Service'},
    {label: 'Craft, Vehicles, Agriculture and Forestry', value: 'Craft, Vehicles, Agriculture and Forestry'},
    {label: 'Purchasing, Logistics, Transport, Traffic', value: 'Purchasing, Logistics, Transport, Traffic'},
    {label: 'Culture, Sport, Wellness', value: 'Culture, Sport, Wellness'},
    {label: 'Security / Police / Customs / Rescue', value: 'Security / Police / Customs / Rescue'},
    {label: 'Information Technology, Telecommunications', value: 'Information Technology, Telecommunications'},
    {label: 'Architecture', value: 'Architecture'},
    {label: 'Bank, Insurance', value: 'Bank, Insurance'},
    {label: 'Chemistry, Pharmaceuticals, Biotechnology', value: 'Chemistry, Pharmaceuticals, Biotechnology'},
    {label: 'Consulting, Human Resources, Legal', value: 'Consulting, Human Resources, Legal'},
    {label: 'Finance, Fiduciary, Real Estate', value: 'Finance, Fiduciary, Real Estate'},
    {label: 'Industry, Mechanical Engineering, Plant Construction, Production', value: 'Industry, Mechanical Engineering, Plant Construction, Production'},
    {label: 'Marketing, Communication, Media, Printing', value: 'Marketing, Communication, Media, Printing'},
    {label: 'Mechanics, Electronics', value: 'Mechanics, Electronics'},
    {label: 'other', value: 'other'}
  ];

    static languages = [
        {label: 'swiss-german', value: 'swiss-german'},
        {label: 'german', value: 'german'},
        {label: 'french', value: 'french'},
        {label: 'italian', value: 'italian'},
        {label: 'english', value: 'english'},
        {label: 'albanian', value: 'albanian'},
        {label: 'spanish', value: 'spanish'},
        {label: 'chinese', value: 'chinese'},
        {label: 'portuguese', value: 'portuguese'},
        {label: 'turkish', value: 'turkish'},
        {label: 'croatian', value: 'croatian'},
        {label: 'serbian', value: 'serbian'},
        {label: 'greek', value: 'greek'},
        {label: 'dutch', value: 'dutch'},
        {label: 'danish', value: 'danish'},
        {label: 'norwegian', value: 'norwegian'},
        {label: 'swedish', value: 'swedish'},
        {label: 'finnish', value: 'finnish'},
        {label: 'hungarian', value: 'hungarian'},
        {label: 'czech', value: 'czech'},
        {label: 'polish', value: 'polish'},
        {label: 'russian', value: 'russian'},
        {label: 'arabic', value: 'arabic'},
        {label: 'thai', value: 'thai'},
        {label: 'farsi', value: 'farsi'},
        {label: 'rhaeto-romanic', value: 'rhaeto-romanic'},
        {label: 'catalan', value: 'catalan'},
        {label: 'korean', value: 'korean'},
        {label: 'hindi', value: 'hindi'},
        {label: 'ukrainian', value: 'ukrainian'},
        {label: 'slovak', value: 'slovak'},
        {label: 'hebrew', value: 'hebrew'},
        {label: 'japanese', value: 'japanese'},
        {label: 'indonesian', value: 'indonesian'},
        {label: 'latvian', value: 'latvian'},
        {label: 'vietnamese', value: 'vietnamese'},
        {label: 'sinhala', value:'sinhala'},
        {label: 'malagasy', value:'malagasy'},
        {label: 'other', value: 'other'}
    ];

    static languagesSkillLevel = [
        {label: 'mother tongue', value: '9'},
        {label: 'fluent', value: '6'},
        {label: 'advanced', value: '3'},
        {label: 'beginner', value: '0'}
    ];

    static nationality = [
        {label: 'afghan', value: 'afghan'},
        {label: 'albanian', value: 'albanian'},
        {label: 'algerian', value: 'algerian'},
        {label: 'american', value: 'american'},
        {label: 'andorran', value: 'andorran'},
        {label: 'angolan', value: 'angolan'},
        {label: 'antiguans', value: 'antiguans'},
        {label: 'argentinean', value: 'argentinean'},
        {label: 'armenian', value: 'armenian'},
        {label: 'australian', value: 'australian'},
        {label: 'austrian', value: 'austrian'},
        {label: 'azerbaijani', value: 'azerbaijani'},
        {label: 'anguilla', value: 'anguilla'},
        {label: 'netherlands antilles', value: 'netherlands antilles'}, 
        {label: 'antarctica', value: 'antarctica'},
        {label: 'aruba', value: 'aruba'},
        {label: 'bahamian', value: 'bahamian'},
        {label: 'bahraini', value: 'bahraini'},
        {label: 'bangladeshi', value: 'bangladeshi'},
        {label: 'barbadian', value: 'barbadian'},
        {label: 'barbudans', value: 'barbudans'},
        {label: 'batswana', value: 'batswana'},
        {label: 'belarusian', value: 'belarusian'},
        {label: 'belgian', value: 'belgian'},
        {label: 'belizean', value: 'belizean'},
        {label: 'beninese', value: 'beninese'},
        {label: 'bhutanese', value: 'bhutanese'},
        {label: 'bolivian', value: 'bolivian'},
        {label: 'bosnian', value: 'bosnian'},
        {label: 'brazilian', value: 'brazilian'},
        {label: 'british', value: 'british'},
        {label: 'bruneian', value: 'bruneian'},
        {label: 'bulgarian', value: 'bulgarian'},
        {label: 'burkinabe', value: 'burkinabe'},
        {label: 'burmese', value: 'burmese'},
        {label: 'burundian', value: 'burundian'},
        {label: 'bermuda', value: 'bermuda'},
        {label: 'bouvet island', value: 'bouvet island'},
        {label: 'british indian ocean territory', value: 'british indian ocean territory'},
        {label: 'british virgin islands', value: 'british virgin islands'},
        {label: 'cambodian', value: 'cambodian'},
        {label: 'cameroonian', value: 'cameroonian'},
        {label: 'canadian', value: 'canadian'},
        {label: 'cape verdean', value: 'cape verdean'},
        {label: 'central african', value: 'central african'},
        {label: 'chadian', value: 'chadian'},
        {label: 'chilean', value: 'chilean'},
        {label: 'colombian', value: 'colombian'},
        {label: 'comoran', value: 'comoran'},
        {label: 'congolese', value: 'congolese'},
        {label: 'costa rican', value: 'costa rican'},
        {label: 'cuban', value: 'cuban'},
        {label: 'cypriot', value: 'cypriot'},
        {label: 'cocos island', value: 'cocos island'},
        {label: 'cook islands', value: 'cook islands'},
        {label: 'christmas islands', value: 'christmas islands'},
        {label: 'dominican', value: 'dominican'},
        {label: 'east timorese', value: 'east timorese'},
        {label: 'ecuadorean', value: 'ecuadorean'},
        {label: 'egyptian', value: 'egyptian'},
        {label: 'emirian', value: 'emirian'},
        {label: 'equatorial guinean', value: 'equatorial guinean'},
        {label: 'eritrean', value: 'eritrean'},
        {label: 'estonian', value: 'estonian'},
        {label: 'ethiopian', value: 'ethiopian'},
        {label: 'fijian', value: 'fijian'},
        {label: 'filipino', value: 'filipino'},
        {label: 'falkland islands', value: 'falkland islands'},
        {label: 'french guiana', value: 'french guiana'},
        {label: 'french polynesia', value: 'french polynesia'},
        {label: 'french southern territories', value: 'french southern territories'},
        {label: 'gabonese', value: 'gabonese'},
        {label: 'gambian', value: 'gambian'},
        {label: 'georgian', value: 'georgian'},
        {label: 'ghanaian', value: 'ghanaian'},
        {label: 'grenadian', value: 'grenadian'},
        {label: 'guatemalan', value: 'guatemalan'},
        {label: 'guinea-bissauan', value: 'guinea-bissauan'},
        {label: 'guinean', value: 'guinean'},
        {label: 'guyanese', value: 'guyanese'},
        {label: 'gibraltar', value: 'gibraltar'},
        {label: 'guadeloupe', value: 'guadeloupe'},
        {label: 'greenland', value: 'greenland'},
        {label: 'guam', value: 'guam'},
        {label: 'haitian', value: 'haitian'},
        {label: 'herzegovinian', value: 'herzegovinian'},
        {label: 'honduran', value: 'honduran'},
        {label: 'hong kong', value: 'hong kong'},
        {label: 'heard and mcDonald islands', value: 'heard and mcDonald islands'},
        {label: 'icelander', value: 'icelander'},
        {label: 'indian', value: 'indian'},
        {label: 'indonesian', value: 'indonesian'},
        {label: 'iranian', value: 'iranian'},
        {label: 'iraqi', value: 'iraqi'},
        {label: 'irish', value: 'irish'},
        {label: 'israeli', value: 'israeli'},
        {label: 'ivorian', value: 'ivorian'},
        {label: 'jamaican', value: 'jamaican'},
        {label: 'japanese', value: 'japanese'},
        {label: 'jordanian', value: 'jordanian'},
        {label: 'kazakhstani', value: 'kazakhstani'},
        {label: 'kenyan', value: 'kenyan'},
        {label: 'kittian and nevisian', value: 'kittian and nevisian'},
        {label: 'kuwaiti', value: 'kuwaiti'},
        {label: 'kyrgyz', value: 'kyrgyz'},
        {label: 'laotian', value: 'laotian'},
        {label: 'latvian', value: 'latvian'},
        {label: 'lebanese', value: 'lebanese'},
        {label: 'liberian', value: 'liberian'},
        {label: 'libyan', value: 'libyan'},
        {label: 'liechtensteiner', value: 'liechtensteiner'},
        {label: 'lithuanian', value: 'lithuanian'},
        {label: 'luxembourger', value: 'luxembourger'},
        {label: 'macedonian', value: 'macedonian'},
        {label: 'malagasy', value: 'malagasy'},
        {label: 'malawian', value: 'malawian'},
        {label: 'malaysian', value: 'malaysian'},
        {label: 'maldivan', value: 'maldivan'},
        {label: 'malian', value: 'malian'},
        {label: 'maltese', value: 'maltese'},
        {label: 'marshallese', value: 'marshallese'},
        {label: 'mauritanian', value: 'mauritanian'},
        {label: 'mauritian', value: 'mauritian'},
        {label: 'mexican', value: 'mexican'},
        {label: 'micronesian', value: 'micronesian'},
        {label: 'moldovan', value: 'moldovan'},
        {label: 'monacan', value: 'monacan'},
        {label: 'mongolian', value: 'mongolian'},
        {label: 'moroccan', value: 'moroccan'},
        {label: 'mosotho', value: 'mosotho'},
        {label: 'motswana', value: 'motswana'},
        {label: 'mozambican', value: 'mozambican'},
        {label: 'macau', value: 'macau'},
        {label: 'martinique', value: 'martinique'},
        {label: 'montserrat', value: 'montserrat'},
        {label: 'mayotte', value: 'mayotte'},
        {label: 'namibian', value: 'namibian'},
        {label: 'nauruan', value: 'nauruan'},
        {label: 'nepalese', value: 'nepalese'},
        {label: 'new zealander', value: 'new zealander'},
        {label: 'ni-vanuatu', value: 'ni-vanuatu'},
        {label: 'nicaraguan', value: 'nicaraguan'},
        {label: 'nigerien', value: 'nigerien'},
        {label: 'north korean', value: 'north korean'},
        {label: 'northern irish', value: 'northern irish'},
        {label: 'northern mariana islands', value: 'northern mariana islands'},
        {label: 'new caledonia', value: 'new caledonia'},
        {label: 'norfolk island', value: 'norfolk island'},
        {label: 'omani', value: 'omani'},
        {label: 'pakistani', value: 'pakistani'},
        {label: 'palauan', value: 'palauan'},
        {label: 'panamanian', value: 'panamanian'},
        {label: 'papua new guinean', value: 'papua new guinean'},
        {label: 'paraguayan', value: 'paraguayan'},
        {label: 'peruvian', value: 'peruvian'},
        {label: 'pitcairn islands', value: 'pitcairn islands'},
        {label: 'puerto rico', value: 'puerto rico'},
        {label: 'palestine', value: 'palestine'},
        {label: 'qatari', value: 'qatari'},
        {label: 'romanian', value: 'romanian'},
        {label: 'rwandan', value: 'rwandan'},
        {label: 'Réunion', value: 'Réunion'},
        {label: 'saint lucian', value: 'saint lucian'},
        {label: 'salvadoran', value: 'salvadoran'},
        {label: 'samoan', value: 'samoan'},
        {label: 'san marinese', value: 'san marinese'},
        {label: 'sao tomean', value: 'sao tomean'},
        {label: 'saudi', value: 'saudi'},
        {label: 'scottish', value: 'scottish'},
        {label: 'senegalese', value: 'senegalese'},
        {label: 'seychellois', value: 'seychellois'},
        {label: 'sierra leonean', value: 'sierra leonean'},
        {label: 'singaporean', value: 'singaporean'},
        {label: 'slovakian', value: 'slovakian'},
        {label: 'slovenian', value: 'slovenian'},
        {label: 'solomon islander', value: 'solomon islander'},
        {label: 'somali', value: 'somali'},
        {label: 'south african', value: 'south african'},
        {label: 'south korean', value: 'south korean'},
        {label: 'sri lankan', value: 'sri lankan'},
        {label: 'sudanese', value: 'sudanese'},
        {label: 'surinamer', value: 'surinamer'},
        {label: 'swazi', value: 'swazi'},
        {label: 'swiss', value: 'swiss'},
        {label: 'syrian', value: 'syrian'},
        {label: 'south georgia and the south sandwich islands', value: 'south georgia and the south sandwich islands'},
        {label: 'saint pierre and miquelon', value: 'saint pierre and miquelon'},
        {label: 'saint helena', value: 'saint helena'},
        {label: 'svalbard and jan mayen', value: 'svalbard and jan mayen'},
        {label: 'stateless', value: 'stateless'},
        {label: 'taiwanese', value: 'taiwanese'},
        {label: 'tajik', value: 'tajik'},
        {label: 'tanzanian', value: 'tanzanian'},
        {label: 'thai', value: 'thai'},
        {label: 'togolese', value: 'togolese'},
        {label: 'tongan', value: 'tongan'},
        {label: 'trinidadian or tobagonian', value: 'trinidadian or tobagonian'},
        {label: 'tunisian', value: 'tunisian'},
        {label: 'tuvaluan', value: 'tuvaluan'},
        {label: 'turks and caicos islands', value: 'turks and caicos islands'},
        {label: 'tibet', value: 'tibet'},
        {label: 'tokelau', value: 'tokelau'},
        {label: 'ugandan', value: 'ugandan'},
        {label: 'ukrainian', value: 'ukrainian'},
        {label: 'uruguayan', value: 'uruguayan'},
        {label: 'uzbekistani', value: 'uzbekistani'},
        {label: 'united utates minor outlying islands', value: 'united utates minor outlying islands'},
        {label: 'venezuelan', value: 'venezuelan'},
        {label: 'vietnamese', value: 'vietnamese'},
        {label: 'welsh', value: 'welsh'},
        {label: 'western sahara', value: 'western sahara'},
        {label: 'wallis and futuna', value: 'wallis and futuna'},
        {label: 'yemenite', value: 'yemenite'},
        {label: 'zambian', value: 'zambian'},
        {label: 'zimbabwean', value: 'zimbabwean'}
    ];

    static maritalStatus = [
        {label: 'single', value: 'single'},
        {label: 'married', value: 'married'},
        {label: 'divorced', value: 'divorced'},
        {label: 'separated', value: 'separated'},
        {label: 'widowed', value: 'widowed'},
        {label: 'other', value: 'other'}
    ];

    static workPermit = [
        {label: 'Permit B', value: 'permit b'},
        {label: 'Permit C', value: 'permit c'},
        {label: 'Permit F', value: 'permit f'},
        {label: 'Permit G', value: 'permit g'},
        {label: 'Permit L', value: 'permit l'},
        {label: 'Permit N', value: 'permit n'},
        {label: 'Notification procedure', value: 'notification procedure'},
        {label: 'B with spouse/husband C', value: 'b spouse/husband c'},
        {label: 'No Work Permit', value: 'no permit'},
    ];

    static accountType = [
        {label: 'bank', value: 'bank'},
        {label: 'post', value: 'post'}
    ];

    static carBrand = [
        {label: 'Alfa Romeo', value: 'Alfa Romeo'},
        {label: 'Aston Martin', value: 'Aston Martin'},
        {label: 'Audi', value: 'Audi'},
        {label: 'Bentley', value: 'Bentley'},
        {label: 'Benz', value: 'Benz'},
        {label: 'BMW', value: 'BMW'},
        {label: 'Bugatti', value: 'Bugatti'},
        {label: 'Cadillac', value: 'Cadillac'},
        {label: 'Chevrolet', value: 'Chevrolet'},
        {label: 'Chrysler', value: 'Chrysler'},
        {label: 'Citroen', value: 'Citroen'},
        {label: 'Corvette', value: 'Corvette'},
        {label: 'DAF', value: 'DAF'},
        {label: 'Dacia', value: 'Dacia'},
        {label: 'Daewoo', value: 'Daewoo'},
        {label: 'Daihatsu', value: 'Daihatsu'},
        {label: 'Datsun', value: 'Datsun'},
        {label: 'De Lorean', value: 'De Lorean'},
        {label: 'Dino', value: 'Dino'},
        {label: 'Dodge', value: 'Dodge'},
        {label: 'Farboud', value: 'Farboud'},
        {label: 'Ferrari', value: 'Ferrari'},
        {label: 'Fiat', value: 'Fiat'},
        {label: 'Ford', value: 'Ford'},
        {label: 'Honda', value: 'Honda'},
        {label: 'Hummer', value: 'Hummer'},
        {label: 'Hyundai', value: 'Hyundai'},
        {label: 'Jaguar', value: 'Jaguar'},
        {label: 'Jeep', value: 'Jeep'},
        {label: 'KIA', value: 'KIA'},
        {label: 'Koenigsegg', value: 'Koenigsegg'},
        {label: 'Lada', value: 'Lada'},
        {label: 'Lamborghini', value: 'Lamborghini'},
        {label: 'Lancia', value: 'Lancia'},
        {label: 'Land Rover', value: 'Land Rover'},
        {label: 'Lexus', value: 'Lexus'},
        {label: 'Ligier', value: 'Ligier'},
        {label: 'Lincoln', value: 'Lincoln'},
        {label: 'Lotus', value: 'Lotus'},
        {label: 'Martini', value: 'Martini'},
        {label: 'Maserati', value: 'Maserati'},
        {label: 'Maybach', value: 'Maybach'},
        {label: 'Mazda', value: 'Mazda'},
        {label: 'McLaren', value: 'McLaren'},
        {label: 'Mercedes', value: 'Mercedes'},
        {label: 'Mercedes-Benz', value: 'Mercedes-Benz'},
        {label: 'Mini', value: 'Mini'},
        {label: 'Mitsubishi', value: 'Mitsubishi'},
        {label: 'Nissan', value: 'Nissan'},
        {label: 'Noble', value: 'Noble'},
        {label: 'Opel', value: 'Opel'},
        {label: 'Peugeot', value: 'Peugeot'},
        {label: 'Pontiac', value: 'Pontiac'},
        {label: 'Porsche', value: 'Porsche'},
        {label: 'Renault', value: 'Renault'},
        {label: 'Rolls-Royce', value: 'Rolls-Royce'},
        {label: 'Rover', value: 'Rover'},
        {label: 'Saab', value: 'Saab'},
        {label: 'Seat', value: 'Seat'},
        {label: 'Skoda', value: 'Skoda'},
        {label: 'Smart', value: 'Smart'},
        {label: 'Spyker', value: 'Spyker'},
        {label: 'Subaru', value: 'Subaru'},
        {label: 'Suzuki', value: 'Suzuki'},
        {label: 'Toyota', value: 'Toyota'},
        {label: 'Vauxhall', value: 'Vauxhall'},
        {label: 'Volkswagen', value: 'Volkswagen'},
        {label: 'Volvo', value: 'Volvo'},
    ];

    static carBodyType = [
        {label: 'SUV', value: 'suv'},
        {label: 'Hatchback', value: 'hatchback'},
        {label: 'Break', value: 'break'},
        {label: 'Coupé', value: 'coupé'},
        {label: 'Cabrio', value: 'cabrio'},
        {label: 'Limousine', value: 'limousine'},
        {label: 'Van', value: 'van'},
        {label: 'Pickup', value: 'pickup'},
    ];

    static carSize = [
        {label: 'Micro', value: 'micro'},
        {label: 'Small', value: 'small'},
        {label: 'Medium', value: 'medium'},
        {label: 'Big', value: 'big'},
    ];

    static days = [
        {label: 'monday', value: 'monday'},
        {label: 'tuesday', value: 'tuesday'},
        {label: 'wednesday', value: 'wednesday'},
        {label: 'thursday', value: 'thursday'},
        {label: 'friday', value: 'friday'},
        {label: 'saturday', value: 'saturday'},
        {label: 'sunday', value: 'sunday'},
    ];

    static countryCodes = [
        {label: '+41', value: '+41'},
        {label: '+1', value: '+1'},
        {label: '+7', value: '+7'},
        {label: '+20', value: '+20'},
        {label: '+27', value: '+27'},
        {label: '+30', value: '+30'},
        {label: '+31', value: '+31'},
        {label: '+32', value: '+32'},
        {label: '+33', value: '+33'},
        {label: '+34', value: '+34'},
        {label: '+36', value: '+36'},
        {label: '+39', value: '+39'},
        {label: '+40', value: '+40'},
        {label: '+43', value: '+43'},
        {label: '+44', value: '+44'},
        {label: '+45', value: '+45'},
        {label: '+46', value: '+46'},
        {label: '+47', value: '+47'},
        {label: '+48', value: '+48'},
        {label: '+49', value: '+49'},
        {label: '+51', value: '+51'},
        {label: '+52', value: '+52'},
        {label: '+53', value: '+53'},
        {label: '+54', value: '+54'},
        {label: '+55', value: '+55'},
        {label: '+56', value: '+56'},
        {label: '+57', value: '+57'},
        {label: '+58', value: '+58'},
        {label: '+60', value: '+60'},
        {label: '+61', value: '+61'},
        {label: '+62', value: '+62'},
        {label: '+63', value: '+63'},
        {label: '+64', value: '+64'},
        {label: '+65', value: '+65'},
        {label: '+66', value: '+66'},
        {label: '+81', value: '+81'},
        {label: '+82', value: '+82'},
        {label: '+84', value: '+84'},
        {label: '+86', value: '+86'},
        {label: '+90', value: '+90'},
        {label: '+91', value: '+91'},
        {label: '+92', value: '+92'},
        {label: '+93', value: '+93'},
        {label: '+94', value: '+94'},
        {label: '+95', value: '+95'},
        {label: '+98', value: '+98'},
        {label: '+211', value: '+211'},
        {label: '+212', value: '+212'},
        {label: '+213', value: '+213'},
        {label: '+216', value: '+216'},
        {label: '+218', value: '+218'},
        {label: '+220', value: '+220'},
        {label: '+221', value: '+221'},
        {label: '+222', value: '+222'},
        {label: '+223', value: '+223'},
        {label: '+224', value: '+224'},
        {label: '+225', value: '+225'},
        {label: '+226', value: '+226'},
        {label: '+227', value: '+227'},
        {label: '+228', value: '+228'},
        {label: '+229', value: '+229'},
        {label: '+230', value: '+230'},
        {label: '+231', value: '+231'},
        {label: '+232', value: '+232'},
        {label: '+233', value: '+233'},
        {label: '+234', value: '+234'},
        {label: '+235', value: '+235'},
        {label: '+236', value: '+236'},
        {label: '+237', value: '+237'},
        {label: '+238', value: '+238'},
        {label: '+239', value: '+239'},
        {label: '+240', value: '+240'},
        {label: '+241', value: '+241'},
        {label: '+242', value: '+242'},
        {label: '+243', value: '+243'},
        {label: '+244', value: '+244'},
        {label: '+245', value: '+245'},
        {label: '+246', value: '+246'},
        {label: '+248', value: '+248'},
        {label: '+249', value: '+249'},
        {label: '+250', value: '+250'},
        {label: '+251', value: '+251'},
        {label: '+252', value: '+252'},
        {label: '+253', value: '+253'},
        {label: '+254', value: '+254'},
        {label: '+255', value: '+255'},
        {label: '+256', value: '+256'},
        {label: '+257', value: '+257'},
        {label: '+258', value: '+258'},
        {label: '+260', value: '+260'},
        {label: '+261', value: '+261'},
        {label: '+262', value: '+262'},
        {label: '+263', value: '+263'},
        {label: '+264', value: '+264'},
        {label: '+265', value: '+265'},
        {label: '+266', value: '+266'},
        {label: '+267', value: '+267'},
        {label: '+268', value: '+268'},
        {label: '+269', value: '+269'},
        {label: '+290', value: '+290'},
        {label: '+291', value: '+291'},
        {label: '+297', value: '+297'},
        {label: '+298', value: '+298'},
        {label: '+299', value: '+299'},
        {label: '+350', value: '+350'},
        {label: '+351', value: '+351'},
        {label: '+352', value: '+352'},
        {label: '+353', value: '+353'},
        {label: '+354', value: '+354'},
        {label: '+355', value: '+355'},
        {label: '+356', value: '+356'},
        {label: '+357', value: '+357'},
        {label: '+358', value: '+358'},
        {label: '+359', value: '+359'},
        {label: '+370', value: '+370'},
        {label: '+371', value: '+371'},
        {label: '+372', value: '+372'},
        {label: '+373', value: '+373'},
        {label: '+374', value: '+374'},
        {label: '+375', value: '+375'},
        {label: '+376', value: '+376'},
        {label: '+377', value: '+377'},
        {label: '+378', value: '+378'},
        {label: '+379', value: '+379'},
        {label: '+380', value: '+380'},
        {label: '+381', value: '+381'},
        {label: '+382', value: '+382'},
        {label: '+383', value: '+383'},
        {label: '+385', value: '+385'},
        {label: '+386', value: '+386'},
        {label: '+387', value: '+387'},
        {label: '+389', value: '+389'},
        {label: '+420', value: '+420'},
        {label: '+421', value: '+421'},
        {label: '+423', value: '+423'},
        {label: '+500', value: '+500'},
        {label: '+501', value: '+501'},
        {label: '+502', value: '+502'},
        {label: '+503', value: '+503'},
        {label: '+504', value: '+504'},
        {label: '+505', value: '+505'},
        {label: '+506', value: '+506'},
        {label: '+507', value: '+507'},
        {label: '+508', value: '+508'},
        {label: '+509', value: '+509'},
        {label: '+590', value: '+590'},
        {label: '+591', value: '+591'},
        {label: '+592', value: '+592'},
        {label: '+593', value: '+593'},
        {label: '+595', value: '+595'},
        {label: '+597', value: '+597'},
        {label: '+598', value: '+598'},
        {label: '+599', value: '+599'},
        {label: '+670', value: '+670'},
        {label: '+672', value: '+672'},
        {label: '+673', value: '+673'},
        {label: '+674', value: '+674'},
        {label: '+675', value: '+675'},
        {label: '+676', value: '+676'},
        {label: '+677', value: '+677'},
        {label: '+678', value: '+678'},
        {label: '+679', value: '+679'},
        {label: '+680', value: '+680'},
        {label: '+681', value: '+681'},
        {label: '+682', value: '+682'},
        {label: '+683', value: '+683'},
        {label: '+685', value: '+685'},
        {label: '+686', value: '+686'},
        {label: '+687', value: '+687'},
        {label: '+688', value: '+688'},
        {label: '+689', value: '+689'},
        {label: '+690', value: '+690'},
        {label: '+691', value: '+691'},
        {label: '+692', value: '+692'},
        {label: '+850', value: '+850'},
        {label: '+852', value: '+852'},
        {label: '+853', value: '+853'},
        {label: '+855', value: '+855'},
        {label: '+856', value: '+856'},
        {label: '+880', value: '+880'},
        {label: '+886', value: '+886'},
        {label: '+960', value: '+960'},
        {label: '+961', value: '+961'},
        {label: '+962', value: '+962'},
        {label: '+963', value: '+963'},
        {label: '+964', value: '+964'},
        {label: '+965', value: '+965'},
        {label: '+966', value: '+966'},
        {label: '+967', value: '+967'},
        {label: '+968', value: '+968'},
        {label: '+970', value: '+970'},
        {label: '+971', value: '+971'},
        {label: '+972', value: '+972'},
        {label: '+973', value: '+973'},
        {label: '+974', value: '+974'},
        {label: '+975', value: '+975'},
        {label: '+976', value: '+976'},
        {label: '+977', value: '+977'},
        {label: '+992', value: '+992'},
        {label: '+993', value: '+993'},
        {label: '+994', value: '+994'},
        {label: '+995', value: '+995'},
        {label: '+996', value: '+996'},
        {label: '+998', value: '+998'},

    ];

    static jobInterests = [
        { label: "automotive", value: "automotive" },
        { label: "telecommunication", value: "telecommunication" },
        { label: "it", value: "it" },
        { label: "sport", value: "sport" },
        { label: "beauty", value: "beauty" },
        { label: "financial products", value: "financial_products" },
        { label: "pharmaceutical products", value: "pharmaceutical_products" },
        { label: "pet", value: "pet" },
        { label: "food", value: "food" },
        { label: "Non-Food", value: "nonfoods" },
        { label: "tobacco", value: "tobacco" },
        { label: "alcoholic beverages", value: "alcoholic_beverages" },
    ];
    
    static jobTypes = [
        { label: "promoter", value: "promotor" },
        { label: "merchandising", value: "merchandising" },
        { label: "mystery shopping", value: "mystery_shopping" },
        { label: "logistics", value: "logistics" },
        { label: "reception", value: "reception" },
        { label: "service", value: "service" },
        { label: "bar", value: "bar" },
        { label: "kitchen", value: "kitchen" },
        { label: "security", value: "security" },
        { label: "care", value: "care" },
        { label: "cleaning", value: "cleaning" },
    ];
    
    static drivingLicenceTypes = [
        { label: "A1", value: "l_a1" },
        { label: "A", value: "l_a" },
        { label: "B", value: "l_b" },
        { label: "BE", value: "l_be" },
        { label: "D", value: "l_d" },
        { label: "DE", value: "l_de" },
        { label: "C", value: "l_c" },
        { label: "CE", value: "l_ce" },
        { label: "C1", value: "l_c1" },
        { label: "C1E", value: "l_c1e" },
        { label: "D1", value: "l_d1" },
        { label: "D1E", value: "l_d1e" },
        { label: "F", value: "l_f" },
        { label: "G", value: "l_g" },
        { label: "M", value: "l_m" },
    ];
    
    static profileOptions = [
        {
          label: "personal details",
          value: "personalDetails",
          iconName: "personal-details",
          visibleToEmployee: true,
        },
        {
          label: "ratings",
          value: "ratings",
          iconName: "ratings",
          visibleToEmployee: false,
        },
        {
          label: "internal remarks",
          value: "internalRemarks",
          iconName: "internal-remarks",
          visibleToEmployee: false,
        },
        {
          label: "employees list",
          value: "employeesList",
          iconName: "employees-list",
          visibleToEmployee: false,
        },
        {
          label: "future jobs",
          value: "acceptedJobs",
          iconName: "accepted-jobs",
          visibleToEmployee: true,
        },
        {
          label: "job history",
          value: "jobHistory",
          iconName: "job-history",
          visibleToEmployee: true,
        },
        {
          label: "imported job history",
          value: "importedjobHistory",
          iconName: "imported-job-history",
          visibleToEmployee: true,
        },
        {
          label: "appearance",
          value: "appearance",
          iconName: "appearance",
          visibleToEmployee: true,
        },
        // {
        //   label: "time management",
        //   value: "timeManagement",
        //   iconName: "time-management",
        //   visibleToEmployee: true,
        // },
        {
          label: "images",
          value: "images",
          iconName: "images",
          visibleToEmployee: true,
        },
        {
          label: "skills",
          value: "skills",
          iconName: "skills",
          visibleToEmployee: true,
        },
        {
          label: "contact information",
          value: "contactInformation",
          iconName: "contact-information",
          visibleToEmployee: true,
        },
        {
          label: "availability",
          value: "availability",
          iconName: "availability",
          visibleToEmployee: true,
        },
        {
          label: "work information",
          value: "workInformation",
          iconName: "work-information",
          visibleToEmployee: true,
        },
        {
          label: "bank information",
          value: "bankInformation",
          iconName: "bank-information",
          visibleToEmployee: true,
        },
        {
          label: "transportation",
          value: "transportation",
          iconName: "transportation",
          visibleToEmployee: true,
        },
        {
          label: "interests",
          value: "interests",
          iconName: "interests",
          visibleToEmployee: true,
        },
    ];
    
    static userStatuses = [
        "incomplete",
        "applied",
        "active",
        "edited",
        "expired",
        "inactive",
    ];

    static filterStatus = [
      {value: 'pending', label: 'pending'},
      {value: 'approved', label: 'approved'},
    ];

    static branches = [
      {name: '110 - Genève', value: '110'},
      {name: '120 - Lausanne', value: '120'},
      {name: '130 - Zürich', value: '130'},
      {name: '140 - Palexpo', value: '140'},
      {name: '150 - Morges', value: '150'},
      {name: '155 - Luzern', value: '155'},
      {name: '160 - Sion', value: '160'},
      {name: '170 - Executive', value: '170'},
      {name: '175 - Bern', value: '175'},
      {name: '190 - Exc H. Staffing', value: '190'},
      {name: '900 - Direction', value: '900' }
    ];

    static consultants = [
      {name: 'AHA - Aurélien Harant', value: 'AHA'},
      {name: 'BOA - Alynne Bosshard', value: 'BOA'},
      {name: 'BOHA - Hanane Bouhyadi', value: 'BOHA'},
      {name: 'CCL - Clara Cassé', value: 'CCL'},
      {name: 'CLB - Clara Boissard', value: 'CLB'},
      {name: 'COLM - Marie Colletti', value: 'COLM'},
      {name: 'CQU - Colin Queloz', value: 'CQU'},
      {name: 'CRH - Christian Reinhardt', value: 'CRH'},
      {name: 'CSO - Céline Sonzogni', value: 'CSO'},
      {name: 'CST - Christophe Stehle', value: 'CST'},
      {name: 'CWI - Chantal Witz', value: 'CWI'},
      {name: 'EMA - Etienne Marchal', value: 'EMA'},
      {name: 'FBOU - Fabienne Bouriaud', value: 'FBOU'},
      {name: 'FGR - Frédéric Garrigue', value: 'FGR'},
      {name: 'GEAK - George Akes', value: 'GEAK'},
      {name: 'HMO - Hinarii Maono', value: 'HMO'},
      {name: 'INFO5 - René Lüdi',  value: 'INFO5'},
      {name: 'JBK - Jean-Benoît Kihn', value: 'JBK'},
      {name: 'JDE - Jennifer Dey', value: 'JDE'},
      {name: 'JHA - Janine Hardegger', value: 'JHA'},
      {name: 'LFO - Lionel Fontaine', value: 'LFO'},
      {name: 'LRU - Lucie Marchal', value: 'LRU'},
      {name: 'MASR - Romain Massiera', value: 'MASR'},
      {name: 'MERM - Mathilde Mercier', value: 'MERM'},
      {name: 'MOM - Manon Monge', value: 'MOM'},
      {name: 'OBI - Oriane Biolay', value: 'OBI'},
      {name: 'PAB - Paméla Brechaire', value: 'PAB'},
      {name: 'RAG - Raphael Girodet', value: 'RAG'},
      {name: 'ROM - Maxime Roquebert', value: 'ROM'},
      {name: 'SCC - Stéphanie Collomb-Clerc', value: 'SCC'},
      {name: 'STGNV - Stagiaire Hotelis',         value: 'STGNV'},
      {name: 'STL - Lara Stöckli', value: 'STL'},
      {name: 'SVS - Sven Schneider', value: 'SVS'},
      {name: 'TRAINING - Centre de formation Training', value: 'TRAINING'},
      {name: 'VHE - Virginie Vergères-Héritier', value: 'VHE'},
      {name: 'VIV - Virginie Vesco', value: 'VIV'},
      {name: 'VRU - Vincent Ruegger', value: 'VRU'},
      {name: 'YPD - Yessica Mabel Prieto Dia', value: 'YP'}
    ];

}
